<template>
  <fieldset>
    <div class="content">
      <h1>{{ $t('page.payment.title') }}</h1>
      <p>{{ $t('page.payment.description') }}</p>
    </div>
    <div>
      <div class="page-payment-box">
        <b-loading
            class="is-primary"
            :active="loading"
            :is-full-page="false"
        >
        </b-loading>
        <div class="box content">
          <div v-if="calculatedCart">
            <h3 class="is-size-5">{{ $t('page.payment.paymentMethod') }}</h3>
            <div class="mt-4">
              <img
                  src="/PayPal.svg"
                  width="160px"
                  alt="PayPal Logo"
              >
            </div>
            <h3 class="is-size-5">{{ $t('page.payment.orderSummary') }}</h3>
            <table class="table">
              <tbody>
              <tr
                  v-for="(cartItem, index) in calculatedCart.cartItems"
                  :key="index"
              >
                <td>
                  {{ $t(`form.package.${cartItem.product.key}.label`) }}
                  <p class="pl-5" v-if="cartItem.product.taxFreeAmount !== '0.00'">
                    {{ $t('page.payment.taxFreeAmount') }} {{ $n(cartItem.product.taxFreeAmount, 'currency') }}
                  </p>
                </td>
                <td class="has-text-right">{{ $n(cartItem.product.price, 'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('page.payment.subtotal') }}</td>
                <td class="has-text-right">{{ $n(calculatedCart.subtotal, 'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('page.payment.taxes.tps') }}</td>
                <td class="has-text-right">{{ $n(calculatedCart.taxes.tps, 'currency') }}</td>
              </tr>
              <tr>
                <td>{{ $t('page.payment.taxes.tvq') }}</td>
                <td class="has-text-right">{{ $n(calculatedCart.taxes.tvq, 'currency') }}</td>
              </tr>
              <tr class="has-text-weight-semibold">
                <td>{{ $t('page.payment.total') }}</td>
                <td class="has-text-right">{{ $n(calculatedCart.total, 'currency') }}</td>
              </tr>
              </tbody>
            </table>
            <b-field
                :type="getFieldType('recaptcha')"
                :message="getFieldMessage('recaptcha')"
            >
              <vue-recaptcha
                  :sitekey="$CAPTCHA_SITE_KEY"
                  @verify="recaptchaVerified"
                  @expired="recaptchaExpired"
                  @error="recaptchaFailed"
                  ref="recaptcha"
                  :loadRecaptchaScript='true'
                  size="normal"
              >
              </vue-recaptcha>
            </b-field>
            <div class="has-text-centered">
              <b-button
                  type="is-blue"
                  icon-right="lock"
                  class="form-navigation__button"
                  @click="pay"
                  :disabled="payDisabled || !recaptchaToken"
                  expanded
              >
                {{ $t('page.payment.pay') }}
              </b-button>
            </div>
            <p class="mt-4 is-size-7">{{ $t('page.payment.fees') }}</p>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import VueRecaptcha from 'vue-recaptcha'
  import cloneDeep from 'lodash-es/cloneDeep'
  import api from '@/api'
  import validator from '@/mixins/validator'

  export default {
    name: 'PagePayment',
    components: {
      VueRecaptcha
    },
    mixins: [
      validator(true)
    ],
    data () {
      return {
        calculatedCart: undefined,
        loading: true,
        payDisabled: false
      }
    },
    computed: {
      ...mapState('form', [
        'currentForm',
        'recaptchaToken',
        'model',
        'files'
      ])
    },
    mounted () {
      this.calculateCart()
    },
    methods: {
      ...mapActions('form', [
        'setIsNavigatingToPaypal',
        'setRecaptchaToken'
      ]),
      calculateCart () {
        api
          .calculateCart(this.getCartRequest())
          .then(result => {
            this.calculatedCart = result.data
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false
            }, 50)
          })
      },
      pay () {
        this.payDisabled = true
        const modelCopy = cloneDeep(this.model)
        modelCopy.locale = this.$i18n.locale

        let formData = new FormData()
        formData.append('formMappings', new Blob([JSON.stringify(modelCopy)], { type: 'application/json' }))
        this.files.forEach((file) => {
          formData.append('files', file)
        })

        api.createForm(this.recaptchaToken, this.currentForm, formData)
          .then(result => {
            return api.checkout(result.data, this.getCartRequest())
          })
          .then(result => {
            this.setIsNavigatingToPaypal(true)
            window.location.href = result.data
          })
      },
      recaptchaExpired () {
        this.setRecaptchaToken(undefined)
      },
      recaptchaFailed () {
        this.setRecaptchaToken(undefined)
      },
      recaptchaVerified (recaptchaToken) {
        this.setRecaptchaToken(recaptchaToken)
      },
      getCartRequest () {
        const cartRequest = [{
          productKey: this.currentForm,
          quantity: 1
        }]

        const options = []
        const taxesDas = this.model?.options?.numbers

        if (this.model?.professionalOrder?.isProfessional) options.push('COMPANY_OF_PROFESSIONALS')
        if (this.model?.options?.physicalbook) options.push('PHYSICAL_BOOK')

        switch (taxesDas) {
          case 'das':
            options.push('DAS')
            break
          case 'taxes':
            options.push('TAXES')
            break
          case 'both':
            options.push('TAXES_DAS')
            break
        }

        options.forEach(option => cartRequest.push({
          productKey: option,
          quantity: 1
        }))

        return cartRequest
      }
    }
  }
</script>
